const buildRecords = (response, subdomain) => {
  return [].concat(
    buildRecord(response.getAt('results.a.0.records', []), subdomain, 'a', 'address'),
    buildRecord(response.getAt('results.aaaa.0.records', []), subdomain, 'aaaa', 'address'),
    buildRecord(response.getAt('results.cname.0.records', []), subdomain, 'cname', 'target'),
    buildRecord(response.getAt('results.mx.0.records', []), subdomain, 'mx', 'exchange', ['preference']),
    buildRecord(response.getAt('results.txt.0.records', []), subdomain, 'txt', 'text')
  );
};

const buildRecord = (values, subdomain, type, attribute, extras = []) => {
  const records = [];

  for (const value of values)
    if (value.name.startsWith(subdomain)) {
      const record = {
        type,
        name: subdomain,
        content: normalizeContent(value, attribute)
      };

      for (const extra of extras)
        record[extra] = normalizeContent(value, extra);

      records.push(record);
    }

  return records;
};

const normalizeContent = (value, attribute) => {
  const string = value[attribute] + '';

  if (string.substr(-1) === '.')
    return string.slice(0, -1);

  return string;
};

export default buildRecords;
